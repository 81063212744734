import '../sass/frontend.scss'

$ = jQuery;

$(document).ready(function () {

    $('.nieuws-slider .entries').owlCarousel({
        items: 2,
        loop: true,
        //margin: 24,
        autoHeight: true,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1024: {
                items: 2
            }
        }
    })

})